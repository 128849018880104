<template>
  <b-card title="Transaksi">
    <div class="row">
      <div class="col-sm-8">

        <div class="float-left transaction-filter">
          <v-select
            v-model="serverParams.transaction_status"
            :options="filterVerifiedValues"
            :reduce="val => val.value"
            :clearable="false"
            input-id="users"
            placeholder="Filter status transaksi"
            @input="onFilterChange"
          />
        </div>
        <div class="ml-1 float-left">
          <b-form-datepicker
            v-model="serverParams.start_date"
            placeholder="Tgl Transaksi awal"
            class="form-control date-filter"
            id="start_date"
            :clearable="true"
            :max="serverParams.end_date"
            @input="onStartDateChange"
            locale="id"
          />
        </div>
        <div class="ml-1 float-left">
          <b-form-datepicker
            v-model="serverParams.end_date"
            placeholder="Tgl Transaksi akhir"
            class="form-control date-filter"
            id="end_date"
            :clearable="true"
            :min="serverParams.start_date"
            @input="onEndDateChange"
            locale="id"
          />
        </div>


      </div>
      <div class="col-sm-4 pt-1 pt-md-0 pb-md-0">
        <div class="custom-search float-right">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="serverParams.search"
                placeholder="Ketik kata kunci disini..."
                name="text"
                class="d-inline-block"
              />
              <b-button size="md" variant="" @click="onSearchChange" class="ml-1">
                Cari
              </b-button>
            </div>
          </b-form-group>
        </div>

      </div>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows && !isLoadingTable"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (serverParams.page == 1) ? (props.row.originalIndex + 1) : ((props.row.originalIndex + 1) + ((serverParams.page - 1) * serverParams.perPage)) }}</span>
        </span>

        <!-- Column: First Name -->
        <span
          v-if="props.column.field === 'total_price_formatted'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ formatPrice(props.row.total_price) }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.transaction_status)">
            {{ getStatusLabel(props.row.transaction_status) }}
          </b-badge>
        </span>

        <span
          v-if="props.column.field === 'voucher_code_formatted'"
          class="text-nowrap"
        >
          {{ (props.row.voucher_code) ? props.row.voucher_code : '-' }}
        </span>

        <!-- Column: Created at -->
        <span
          v-if="props.column.field === 'created_at'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Updated at -->
        <span
          v-if="props.column.field === 'updated_at'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="viewPage(props.row.id)" v-if="$can('view', 'users')">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Lihat</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Data 1 hingga
            </span>
            <b-form-select
              v-model="serverParams.perPage"
              :options="['3','5','10', '25', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> dari {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              v-model="serverParams.page"
              :value="1"
              :total-rows="totalRecords"
              :per-page="serverParams.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage: value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BFormFile, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BBadge, BFormCheckbox, BButtonGroup, BAlert, BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    vSelect,
    BCard,
    BForm,
    BButton,
    BSpinner,
    BBadge,
    BFormCheckbox,
    BButtonGroup,
    BFormFile,
    BAlert,
    BFormDatepicker,
  },
  data() {
    return {
      showToggleVerification: false,
      filterVerifiedValues: [
        {
          label: "Semua Status",
          value: "all"
        },
        {
          label: "Berhasil",
          value: "success"
        },
        {
          label: "Gagal",
          value: "failed"
        },
        {
          label: "Menunggu Pembayaran",
          value: "waiting"
        }
      ],
      userVerifiedFilter: "all",
      isLoading: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'Kode',
          field: 'transaction_number',
        },
        {
          label: 'Metode Pembayaran',
          field: 'payment_method',
        },
        {
          label: 'Ref. Pembayaran',
          field: 'payment_referal_number',
        },
        {
          label: 'Pengguna',
          field: 'user.name',
        },
        {
          label: 'Voucher',
          field: 'voucher_code_formatted',
        },
        {
          label: 'Total',
          field: 'total_price_formatted',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Tgl Transaksi',
          field: 'transaction_date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy',
        },
        {
          label: 'Aksi',
          field: 'action',
        },
      ],
      allRows: [],
      rows: [],

      isLoadingTable: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      formState: "Edit",
      isEdit: false,
      showFormModal: false,
      showDeleteModal: false,
      pageName: "Transaksi",

      totalRecords: 0,
      serverParams: {
        start_date: null,
        end_date: null,
        search: null,
        transaction_status: "all",
        columnFilters: {},
        sortBy: null,
        sortType: null,
        page: 1, 
        perPage: 10
      }
    }
  },
  computed: {
  },
  methods: {
    getStatusLabel(status) {
      if(status == 1) {
        return 'Berhasil'
      }
      else
      if(status == 0) {
        return 'Gagal'
      }
      else
      if(status == 2) {
        return 'Menunggu Pembayaran'
      }
      else {
        return ''
      }
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    onSearchChange() {
      if(this.serverParams.search.length > 0) {
        this.updateParams({search: this.serverParams.search})
      }
      else {
        this.updateParams({search: null})
      }
      
      this.loadItems()
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage})
      this.loadItems()

    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage})
      this.loadItems()
    },

    onSortChange(params) {
      if(params[0].field == "role" || params[0].field == "action") {
        return
      }

      if(this.serverParams.sortBy == params[0].field) {
        if(params[0].type == "asc" && params[0].type == this.serverParams.sortType) {
          this.updateParams({sortType: "desc"})
        }
        else 
        if(params[0].type == "desc" && params[0].type == this.serverParams.sortType){
          this.updateParams({sortType: "asc"})
        }
      }
      else {
        this.updateParams({sortBy: params[0].field})
        this.updateParams({sortType: params[0].type})
      }

      this.loadItems()
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems()
    },

    loadItems() {
      this.isLoadingTable = true
      this.fetchData(this.serverParams).then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.allRows = currentData.rows
            this.rows = currentData.rows
            this.totalRecords = currentData.count
          }

          this.isLoadingTable = false
     })
    },

    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD H:mm:s').format('DD-MM-YYYY HH:mm')
    },
    onStartDateChange(value) {
      this.updateParams({start_date: value})

      if(this.serverParams.start_date != null && this.serverParams.end_date != null) {
        this.loadItems()
      }
    },
    onEndDateChange(value) {
      this.updateParams({end_date: value})

      if(this.serverParams.start_date != null && this.serverParams.end_date != null) {
        this.loadItems()
      }
    },
    onFilterChange(value) {
      this.updateParams({transaction_status: value})
      this.loadItems()
    },
    statusVariant(success) {
      if(success == 1) {
        return 'light-success'
      }
      else
      if(success == 0) {
        return 'light-danger'
      }
      else
      if(success == 2) {
        return 'light-warning'
      }
    },
    viewPage(id) {

      this.$router.push('/transactions/' + id + '/view')

    },
    fetchData(params) {
      this.isLoadingTable = true

      return this.$http.post('/admin/v1/transactions_filtered', params)
    }
  },
  created() {
    this.loadItems()
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';

  .vgt-responsive {
    min-height: 180px;
  }

  .transaction-filter .v-select.vs--single.vs--searchable {
    width: 240px;
  }

  .date-filter {
    min-width: 220px;
  }
</style>